import "./styles.scss";

import React from "react";

const mainClass = "career-content";

const Content = ({ description }) => {
  return (
    description && (
      <section className={mainClass}>
        <div
          className="container container--small"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </section>
    )
  );
};

export default Content;
