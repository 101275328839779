import "./styles.scss";

import React from "react";

import CPinIcon from "icons/facilities/c-pin.svg";

const mainClass = "career-recruitment";

const Recruitment = ({ title, description }) => {
  return (
    <section className={mainClass}>
      <div className="container">
        <div className={`${mainClass}__icon`}>
          <CPinIcon />
        </div>
        {title?.text && (
          <h3>{getHighlightedTitle(title.text, title?.highlight)}</h3>
        )}
        {description && (
          <div
            className={`${mainClass}__description`}
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}
      </div>
    </section>
  );
};

const getHighlightedTitle = (title, highlight) => {
  if (!title) return undefined;
  if (!highlight) return title;

  const split = title.split(highlight);

  if (split.length > 1) {
    const array = [];

    split.forEach((item, index) => {
      array.push(item);

      if (index + 1 < split.length) {
        array.push(highlight);
      }
    });

    return array.map((item, index) =>
      item === highlight ? <span key={index}>{item}</span> : item
    );
  } else {
    return title;
  }
};

export default Recruitment;
