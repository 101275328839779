import "./styles.scss";

import React from "react";

const mainClass = "page-header";

const PageHeader = ({ image, title, thin_title, text, thin_title_large }) => {
  return (
    <div className={mainClass} style={{ backgroundImage: `url(${image})` }}>
      <div className="container">
        <h1>{title}</h1>
        <span
          className={`${mainClass}__thin-title ${
            thin_title_large ? `${mainClass}__thin-title--large` : ""
          }`}
          dangerouslySetInnerHTML={{
            __html: thin_title,
          }}
        />
        {text && <p>{text}</p>}
      </div>
    </div>
  );
};

export default PageHeader;
