import React from "react";
import { graphql } from "gatsby";

import Layout from "components/Layout";
import PageHeader from "components/PageHeader";

import { Content, Recruitment, Apply } from "page_components/career";

import AboutHero from "images/career-bg.jpeg";

const Career = ({ data }) => {
  const pageData = data.wpPage;

  const seo = pageData.seo;
  const schema = JSON.parse(pageData.seo.schema.raw);

  return (
    <Layout
      seo={{ title: seo.title, desc: seo.metaDesc, schema }}
      blue_header
      card_remove
      thin_title_large
      contact_remove
    >
      <PageHeader
        image={AboutHero}
        title="Kariera"
        thin_title={
          "<strong>Let me guide you - pracuj z nami!</strong><br />Dołącz do naszego Zespołu, twórz z nami hotele dla najbardziej wymagających Gości!"
        }
        thin_title_large
      />
      <Content description={pageData.career.careerIntroDescription} />
      <Recruitment
        title={pageData.career.careerRecruitmentTitle}
        description={pageData.career.careerRecruitmentDescription}
      />
      <Apply
        title={pageData.career.careerFormTitle}
        description={pageData.career.careerFormDescription}
        clause={pageData.career.careerFormClause}
      />
    </Layout>
  );
};

export const query = graphql`
  query {
    wpPage(id: { eq: "cG9zdDoyNjg0MA==" }) {
      career {
        careerIntroDescription
        careerRecruitmentTitle {
          text
          highlight
        }
        careerRecruitmentDescription
        careerFormTitle
        careerFormDescription
        careerFormClause
      }
      seo {
        title
        metaDesc
        schema {
          raw
        }
        opengraphTitle
        opengraphDescription
        opengraphType
        opengraphUrl
        opengraphImage {
          sourceUrl
        }
      }
    }
  }
`;

export default Career;
