import "./styles.scss";

import React from "react";
import { FileUploader } from "react-drag-drop-files";

import handleFormFiles from "utils/handleFormFiles";

import IcoCheck from "icons/check-uploader.svg";
import IcoUpload from "icons/upload-cloud.svg";

const File = ({
  form,
  field,
  fileTypes = ["PDF", "DOC", "DOCX"],
  maxFiles = 2,
}) => {
  const { setFieldValue } = form;
  const { value: files, name } = field;

  const handleChange = (selectedFiles) => {
    const formFiles = handleFormFiles(selectedFiles, files, maxFiles);

    setFieldValue(name, formFiles);
  };

  const handleDelete = (file) => {
    const newFiles = files.filter((oldFile) => oldFile.name !== file.name);

    setFieldValue(name, newFiles);
  };

  return (
    <div className="file-group">
      <FileUploader
        hoverTitle=""
        multiple={true}
        handleChange={handleChange}
        types={fileTypes}
      >
        <div className="file-group__area">
          <IcoUpload />
          <span className="file-group__area-description">
            <span>Kliknij, aby przesłać</span> albo przeciągnij i upuść plik
            (maksymalnie {maxFiles} pliki)
          </span>
          <span className="file-group__area-format">
            Akceptowalny format: {fileTypes.join(", ")}.
          </span>
        </div>
      </FileUploader>
      {files && (
        <div className="file-group__files">
          {files.map((file) => (
            <div className="file-group__file" key={file.name}>
              <p>
                <IcoCheck />
                Plik: {file.name}
              </p>

              <button onClick={() => handleDelete(file)}>Usuń plik</button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default File;
