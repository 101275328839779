import "./styles.scss";

import React, { useState } from "react";
import { Field, Form, Formik } from "formik";
import { navigate } from "gatsby";
import axios from "axios";

import Input from "components/form/Input";
import File from "components/form/File";
import Checkbox from "components/form/Checkbox";
import Button from "components/Button";

import contactSchema from "libs/validations/contactSchema";

import MessageIcon from "icons/message.svg";
import Logo from "icons/logo.svg";

const mainClass = "career-apply";

const Apply = ({ title, description, clause }) => {
	const form_id = 26862;
	const [sent, setSent] = useState(false);

	const onSubmit = async (
		{ full_name, email, phone, message, files, legal1 },
		setSubmitting
	) => {
		let formData = new FormData();

		formData.set("fullName", full_name);
		formData.set("email", email);
		formData.set("phone", phone);
		formData.set("message", message);
		if (files?.length > 0) {
			files.forEach((file, index) => {
				if (index === 0) {
					formData.set("plikPierwszy", file);
				} else if (index === 1) {
					formData.set("plikDrugi", file);
				}
			});
		}
		formData.set("zgodaPierwsza", legal1);
		formData.set("_wpcf7_unit_tag", "#wpcf7_unit_tag-" + form_id);

		try {
			await axios.post(
				`https://cms.condo.pl/wp-json/contact-form-7/v1/contact-forms/${form_id}/feedback/`,
				formData,
				{
					headers: { "Content-Type": "multipart/form-data" },
				}
			);
			navigate("/thank-you-page");
			setSent(true);
		} catch (err) {
			setSubmitting(false);
			console.error(err);
		}
	};

	return (
		<section className={mainClass}>
			<div className="container container--small">
				{title && <h3>{title}</h3>}
				{description && (
					<div
						className={`${mainClass}__info`}
						dangerouslySetInnerHTML={{ __html: description }}
					/>
				)}
				{clause && (
					<div
						className={`${mainClass}__block`}
						dangerouslySetInnerHTML={{ __html: `<p>${clause}</p>` }}
					/>
				)}
				<div className={`${mainClass}__icon`}>
					<MessageIcon />
					<Logo />
				</div>
				<h4>Aplikuj bezpośrednio</h4>

				<Formik
					onSubmit={(values, { setSubmitting }) => {
						onSubmit(values, setSubmitting);
					}}
					enableReinitialize
					validationSchema={contactSchema}
					initialValues={{
						full_name: "",
						email: "",
						phone: "",
						message: "",
						files: null,
						legal1: false,
					}}
				>
					{({ isSubmitting }) => (
						<Form className={`${mainClass}__form`}>
							<div className={`${mainClass}__form-grid`}>
								<div className={`${mainClass}__form-column`}>
									<Field
										component={Input}
										label="Imię i Nazwisko"
										placeholder="Wpisz swoje dane"
										type="text"
										name="full_name"
										disabled={sent}
									/>
									<Field
										component={Input}
										label="Adres email*"
										placeholder="Wpisz adres email"
										type="text"
										name="email"
										disabled={sent}
									/>
									<Field
										component={Input}
										label="Telefon*"
										placeholder="+48 xxx xxx xxx"
										type="text"
										name="phone"
										disabled={sent}
									/>
								</div>
								<div className={`${mainClass}__form-column`}>
									<Field
										component={Input}
										label="Opis"
										placeholder="Wpisz dodatkowe informacje...."
										type="text"
										name="message"
										variant="multiline"
										disabled={sent}
									/>
								</div>
							</div>
							<Field
								component={File}
								name="files"
								disabled={sent}
							/>
							<Field
								component={Checkbox}
								label="* Wyrażam zgodę na przetwarzanie przez Oak Property Sp. z o.o. z siedzibą w Warszawie (00-411), ul. Wioślarska 8, moich danych osobowych zawartych w niniejszym formularzu kontaktowym w celu i zakresie koniecznym do realizacji zgłoszenia."
								type="checkbox"
								name="legal1"
								disabled={sent}
							/>

							<Button type="submit" disabled={sent}>
								{!!isSubmitting
									? "Wysyłanie"
									: sent
									? "Aplikacja została wysłana"
									: "Aplikuj"}
							</Button>
						</Form>
					)}
				</Formik>
			</div>
		</section>
	);
};

export default Apply;
