const handleFormFiles = (selectedFiles, currentFiles, maxFiles) => {
    let isAddedWhenOverflow = false
    let tempFiles =
      currentFiles && selectedFiles.length < maxFiles ? [...currentFiles] : []
  
    Array.from(selectedFiles).forEach((selectedFile, index) => {
      if (!currentFiles || selectedFiles.length > maxFiles - 1) {
        if (index < maxFiles) {
          tempFiles.push(selectedFile)
        }
      } else {
        if (
          !currentFiles.some(file => file.name === selectedFile.name) &&
          !isAddedWhenOverflow
        ) {
          if (tempFiles.length >= maxFiles) {
            isAddedWhenOverflow = true
  
            tempFiles.pop()
          }
  
          tempFiles.push(selectedFile)
        }
      }
    })
  
    return tempFiles
  }
  
  export default handleFormFiles
  